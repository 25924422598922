<template>
    <div class=container>
<table class=table  width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">CuRRET:<br>
            </font><span class="style3"><a href="http://www.columbia.edu">Columbia</a>-Utrecht Reflectance and Texture Database</span></div></td>
          </tr>
          <tr>
            <td valign="baseline">
                  <div align="left">
                    <table align="center">
      <tbody><tr><td><a href="http://www.cs.columbia.edu/~dana/"> Kristin J. Dana </a>
          </td><td><a href="http://www.isi.uu.nl/People/Bram/">Bram Van Ginneken </a>
        </td></tr><tr>
          <td><a href="http://www.cs.columbia.edu/~nayar/"> Shree K. Nayar </a>
          </td><td><a href="http://www.fys.ruu.nl/">Jan J. Koenderink </a>
          </td></tr><tr>
                    </tr></tbody></table>
                  </div>              <p align="left"> Researchers at Columbia University and Utrecht University have collaborated in an extensive investigation of the visual appearance of real-world surfaces. This joint effort, sponsored in part by REALISE of the European Commission, the National Science Foundation and by DARPA/ONR under the MURI Grant No. N00014-95-1-0601 has resulted in 3 databases:
                  </p><div align="left">
                    <ol>
                      <li> <b> BRDF <em> (bidirectional reflectance distribution function) </em> database </b> with reflectance measurements for over 60 different samples, each observed with over 200 different combinations of viewing and illumination directions. 
                      </li><li> <b> BRDF parameter database</b> with fitting parameters from two recent BRDF models: the <a href="../../../projects/oren/oren.php">Oren-Nayar</a> model and the <a href="https://cave.cs.columbia.edu/old/software/curet/html/koenbib.html"> Koenderink et al. </a> representation. These BRDF parameters can be directly used for both image analysis and image synthesis. 
                      </li><li> <b> BTF <em> (bidirectional texture function) </em> database </b> with image textures from over 60 different samples, each observed with over 200 different combinations of viewing and illumination directions. Each of these databases is made publicly available for research purposes. For details about the measurements, and fitting procedures a <a href="https://cave.cs.columbia.edu/old/software/curet/doc/techreport.ps.gz"> technical report </a> and <a href="https://cave.cs.columbia.edu/old/software/curet/doc/summary.ps.gz"> summary paper </a> are provided. Also see the following journal paper:
<blockquote>
<div class="publication" data-v-0ff51178=""><b data-v-0ff51178="">"Reflectance and Texture of Real World Surfaces,"<br data-v-0ff51178=""></b><span data-v-0ff51178="">K.J. Dana, B. Van-Ginneken, S.K. Nayar and J.J. Koenderink,<br data-v-0ff51178=""></span><span data-v-0ff51178="">ACM Transactions on Graphics (TOG),<br data-v-0ff51178=""></span><span data-v-0ff51178="">Vol. 18, No. 1, pp. 1-34, Jan. 1999<br data-v-0ff51178=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Dana_TOG99.pdf" target="new" data-v-0ff51178="">PDF</a>] [<a href="reference?bid=120" class="url" data-v-0ff51178="">bib</a>] [<a href="copyright" class="url" data-v-0ff51178="">©</a>] <span data-v-0ff51178=""><a href="projects/categories/project?cid=Graphics+%26+Displays&amp;pid=Bidirectional+Texture+Function" class="url" data-v-0ff51178=""> [Project Page]</a></span><br data-v-0ff51178=""></div>
</blockquote>
                    </li></ol>
                  </div>              
                  </td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p><router-link to="/repository/CURET"> [Return to Database Home Page] </router-link></p>
                </div>
            </div></td>
          </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>